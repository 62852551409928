import React, { useMemo, useCallback } from 'react';
import { DataGrid as DataGridMUI } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { deDE, enUS } from '@mui/x-data-grid/locales';
import { arrayDiff } from '../../utils/common';

const locales = { de: deDE, en: enUS };

export const DataGrid = ({
	rows,
	paginationMode = 'client',
	selectedEntities: selectedEntitiesParam,
	onSelectedEntitiesChange,
	...props
}) => {
	const { i18n } = useTranslation();
	const selectedEntities = useMemo(() => selectedEntitiesParam || [], [selectedEntitiesParam]);
	const selectedIds = useMemo(() => selectedEntities.map((e) => e.id), [selectedEntities]);

	const handleRowSelectionModelChangeClient = useCallback(
		(newSelectedIds) => {
			onSelectedEntitiesChange(newSelectedIds.map((id) => rows.find((r) => r.id === id)));
		},
		[onSelectedEntitiesChange, rows],
	);

	/**
	 * This function controls selected rows of the datagrid. When user selects/deselects a row
	 * it keeps track of old rows from other pages pulled from the server
	 * Client implementation is smaller because the rows from all pages are already pulled
	 */
	const handleRowSelectionModelChangeServer = useCallback(
		(newSelectedIds) => {
			const isRowAdded = newSelectedIds.length > selectedIds.length;
			let newSelectedEntities;

			if (isRowAdded) {
				const addedIds = arrayDiff(newSelectedIds, selectedIds);
				const addedEntities = rows.filter((row) => addedIds.includes(row.id));

				newSelectedEntities = [...selectedEntities, ...addedEntities];
			} else {
				const removedIds = arrayDiff(selectedIds, newSelectedIds);

				newSelectedEntities = selectedEntities.filter((e) => !removedIds.includes(e.id));
			}

			onSelectedEntitiesChange(newSelectedEntities);
		},
		[onSelectedEntitiesChange, rows, selectedEntities, selectedIds],
	);

	const gridProps = {
		...props,
		rows,
		paginationMode,
		localeText: locales[i18n.language].components.MuiDataGrid.defaultProps.localeText,
		keepNonExistentRowsSelected: true,
		// Selected rows count includes default selected entities that may be not in the table, so hiding it
		hideFooterSelectedRowCount: true,
	};

	if (selectedEntitiesParam) {
		gridProps.rowSelectionModel = selectedIds;
	}

	if (onSelectedEntitiesChange) {
		gridProps.onRowSelectionModelChange =
			paginationMode === 'client' ? handleRowSelectionModelChangeClient : handleRowSelectionModelChangeServer;
	}

	return <DataGridMUI {...gridProps} />;
};
