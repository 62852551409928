import React, { useMemo } from 'react';
import { SEARCH_USERS } from '../../services/api-calls';
import { useTranslation } from 'react-i18next';
import { DataGridSearcher } from '../data-grid-searcher';

export const UsersSearcher = ({ selectedUsers, onChange }) => {
	const { t } = useTranslation();
	const columns = useMemo(
		() => [
			{ field: 'name', headerName: t('tables.user.name'), flex: 1 },
			{ field: 'surname', headerName: t('tables.user.surname'), flex: 1 },
			{
				field: 'groups',
				headerName: t('tables.user.groups'),
				valueGetter: (value) => value?.map((group) => group.name).join(', '),
				flex: 2,
				sortable: false,
			},
		],
		[t],
	);

	return (
		<DataGridSearcher
			searchFunc={SEARCH_USERS}
			searchPlaceHolder={t('broadcast.groups.users-search-bar-placeholder')}
			columns={columns}
			selectedEntities={selectedUsers}
			onSelectedEntitiesChange={onChange}
			checkboxSelection
		/>
	);
};
