import React, { useState, useEffect, useCallback, useDeferredValue } from 'react';
import { CircularProgress, IconButton, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const SearchBar = ({ placeHolder, onSearchChange, onSearchPress, loading }) => {
	const [value, setValue] = useState('');
	const deferredValue = useDeferredValue(value);

	useEffect(() => {
		onSearchChange(deferredValue);
	}, [deferredValue, onSearchChange]);

	const handleChange = useCallback((e) => {
		setValue(e.target.value);
	}, []);

	const handleKeyUp = useCallback(
		(e) => {
			if (e.key === 'Enter') {
				onSearchPress(value);
			}
		},
		[onSearchPress, value],
	);

	return (
		<div className="search-bar">
			<Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
				<InputBase
					sx={{ ml: 1, flex: 1 }}
					placeholder={placeHolder}
					inputProps={{ 'aria-label': placeHolder }}
					onChange={handleChange}
					onKeyUp={handleKeyUp}
				/>
				{loading !== undefined && loading ? (
					<IconButton sx={{ p: '10px' }} disabled>
						<CircularProgress size={24} />
					</IconButton>
				) : (
					<IconButton data-testid="search-button" type="button" sx={{ p: '10px' }} aria-label="search" onClick={onSearchPress}>
						<SearchIcon color="primary" />
					</IconButton>
				)}
			</Paper>
		</div>
	);
};
