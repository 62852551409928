import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { SearchBar } from './inputs/search-bar';
import { Box, Stack } from '@mui/material';
import { useLoading } from '../utils/hooks';
import { debouncePromise } from '../utils/common';
import { DataGrid } from './inputs/data-grid';

export const DataGridSearcher = ({ searchFunc: searchFuncParam, searchPlaceHolder, initialPageSize = 5, ...props }) => {
	const [rows, setRows] = useState([]);
	const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: initialPageSize });
	const [sortModel, setSortModel] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [totalRows, setTotalRows] = useState(0);
	const debouncedSearchFunc = useMemo(() => debouncePromise(searchFuncParam), [searchFuncParam]);
	const [searchFunc, loading] = useLoading(debouncedSearchFunc);

	const startSearch = useCallback(() => {
		searchFunc({ searchValue, paginationModel, sortModel, setRows, setTotalRows });
	}, [paginationModel, searchFunc, searchValue, sortModel]);

	useEffect(() => {
		startSearch();
	}, [searchValue, startSearch]);

	const handleSearchChange = useCallback((s) => {
		setSearchValue(s);
		setPaginationModel((prev) => ({ ...prev, page: 0 }));
	}, []);

	return (
		<div>
			<Stack spacing={2} maxWidth={750}>
				<Box width="50%">
					<SearchBar
						placeHolder={searchPlaceHolder}
						loading={rows?.length > 0 ? undefined : loading}
						onSearchChange={handleSearchChange}
						onSearchPress={startSearch}
					/>
				</Box>
				<DataGrid
					{...props}
					rows={rows}
					rowCount={totalRows}
					paginationMode="server"
					pageSizeOptions={[5, 10]}
					loading={loading}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					disableColumnMenu
					sortingMode="server"
					onSortModelChange={setSortModel}
				/>
			</Stack>
		</div>
	);
};
