import React, { useMemo, useCallback } from 'react';
import { SEARCH_USERS } from '../services/api-calls';
import { useTranslation } from 'react-i18next';
import { DataGridSearcher } from './data-grid-searcher';
import { AcceptPopup } from './accept-popup';
import { useLoading, useToggle } from '../utils/hooks';
import { BREAK_RELATIONSHIP } from '../services/api-calls';
import { LoadingButton } from '@mui/lab';

export const RelationshipsRemover = ({ groups }) => {
	const { t } = useTranslation();

	const handleSearch = useCallback(
		async (params) => {
			await SEARCH_USERS({ ...params, groups });
		},
		[groups],
	);

	const columns = useMemo(
		() => [
			{ field: 'name', headerName: t('tables.user.name'), flex: 1 },
			{ field: 'surname', headerName: t('tables.user.surname'), flex: 1 },
			{
				field: 'groups',
				headerName: t('tables.user.groups'),
				valueGetter: (value) => value?.map((group) => group.name).join(', '),
				flex: 2,
				sortable: false,
			},
			{
				headerName: t('tables.user.actions'),
				renderCell: ({ row }) => <BreakButton userId={row.userId} />,
				sortable: false,
				flex: 1,
			},
		],
		[t],
	);

	return (
		<DataGridSearcher
			searchFunc={handleSearch}
			searchPlaceHolder={t('broadcast.groups.users-search-bar-placeholder')}
			columns={columns}
			disableRowSelectionOnClick
			initialPageSize={10}
		/>
	);
};

const BreakButton = ({ userId }) => {
	const { t } = useTranslation();
	const [showModal, toggleModal] = useToggle(false);
	const [removeRelationship, loading] = useLoading(BREAK_RELATIONSHIP);

	const handleBreak = useCallback(() => {
		toggleModal();
		removeRelationship(userId);
	}, [removeRelationship, toggleModal, userId]);

	return (
		<>
			<LoadingButton color="error" variant="contained" loading={loading} onClick={toggleModal}>
				{t('relationships.content.break-button')}
			</LoadingButton>
			<AcceptPopup
				id="remove-user"
				title={t('relationships.break-modal.title')}
				text={t('relationships.break-modal.text')}
				isOpen={showModal}
				isDanger
				onClose={toggleModal}
				action={handleBreak}
			/>
		</>
	);
};
