import i18next from 'i18next';
import { getAsync, postAsync } from 'common-frontend/utils/fetch-helper';
import { alertUser } from 'common-frontend/utils/common';
import { STORES } from './stores';

export const SEND_ID_CARD = async () => {
	const response = await postAsync('/send/schoolid', {});

	if (response.status === 200) {
		sessionStorage.setItem('school_id_was_sent', 'true');
		alertUser(i18next.t('alerts.id-was-sent'), 'success');
	}
};

export const SEND_HOMEWORK = async () => {
	const response = await postAsync('/send/homework', {});

	if (response.status === 200) {
		sessionStorage.setItem('homework_was_sent', 'true');
		alertUser(i18next.t('alerts.homework-was-sent'), 'success');
	}
};

export const GET_DOCUMENTS_LIST = async (page, size, type) => {
	const {
		DocumentsStore: { setDocuments },
	} = STORES;
	const response = await getAsync(`/documents?page=${page - 1}&size=${size}&sort=createdAt,DESC&type=${type}`);

	if (response.status === 200) {
		const data = await response.json();
		setDocuments(data.content, data.totalPages);
	}
};

export const SEND_DOCUMENT = async (uuid, setDocument) => {
	const response = await postAsync('/send-file', { uuid });

	if (response.status === 200) {
		setDocument(await response.json());
	}
};

export const SIGN_DOCUMENT = async (uuid, setDocument) => {
	const response = await postAsync('/sign-file', { uuid });

	if (response.status === 200) {
		setDocument(await response.json());
	}
};

export const VERIFY_DOCUMENT = async (uuid, setDocument) => {
	const response = await postAsync('/verify-file', { uuid });

	if (response.status === 200) {
		setDocument(await response.json());
	}
};

export const REVOKE_DOCUMENT = async (uuid, setDocument) => {
	const response = await postAsync('/revoke-file', { uuid });

	if (response.status === 200) {
		setDocument(await response.json());
	}
};
