import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { RelationshipsRemover } from 'common-frontend/components/relationships-remover';
import { GroupsListSelector } from 'common-frontend/components/inputs/groups-list-selector';

export const RelationshipsPage = observer(() => {
	const [selectedGroups, setSelectedGroups] = useState([]);
	const { t } = useTranslation();

	return (
		<div className="page messages-page">
			<Stack spacing={6}>
				<div>
					<Typography variant="h2" className="mt-32">
						{t('relationships.content.title')}
					</Typography>
				</div>
				<Stack direction="row" spacing={3}>
					<Box width="30%">
						<GroupsListSelector checkedIds={selectedGroups} onChange={setSelectedGroups} />
					</Box>
					<Box width="70%">
						<RelationshipsRemover groups={selectedGroups} />
					</Box>
				</Stack>
			</Stack>
		</div>
	);
});
