import { Box, IconButton, TextField } from '@mui/material';
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';

export const ClearableTextarea = ({ value, disabled, onClear, ...props }) => {
	return (
		<Box sx={{ position: 'relative' }}>
			<TextField
				{...props}
				multiline
				rows={10}
				fullWidth
				sx={{
					'& .MuiOutlinedInput-root': {
						paddingRight: '55px',
					},
				}}
				value={value ?? ''}
				disabled={disabled}
			/>
			{value && !disabled && (
				<IconButton
					data-testid="clear-icon"
					onClick={onClear}
					sx={{
						position: 'absolute',
						top: 8,
						right: 8,
						zIndex: 1,
					}}
				>
					<ClearIcon />
				</IconButton>
			)}
		</Box>
	);
};
