import React from 'react';
import { DataGrid } from './inputs/data-grid';
import { useTranslation } from 'react-i18next';

export const UsersViewer = ({ users, enableSelectingUsers, onChange }) => {
	const { t } = useTranslation();

	const columns = [
		{ field: 'name', headerName: t('tables.user.name'), flex: 1 },
		{ field: 'surname', headerName: t('tables.user.surname'), flex: 1 },
		{
			field: 'groups',
			headerName: t('tables.user.groups'),
			valueGetter: (value) => value?.map((group) => group.name).join(', '),
			flex: 2,
		},
	];

	return (
		<DataGrid
			rows={users}
			columns={columns}
			pageSizeOptions={[5, 10]}
			initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
			disableRowSelectionOnClick={!enableSelectingUsers}
			checkboxSelection={enableSelectingUsers}
			selectedEntities={enableSelectingUsers && users}
			onSelectedEntitiesChange={enableSelectingUsers && onChange}
			hideFooterSelectedRowCount
		/>
	);
};
